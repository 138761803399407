import * as nav from "./modules/navigation.js";
import * as carousel from "./modules/carousels.js";
import animateOnScroll from "./modules/observer.js";

// Stop transitions before page has loaded
window.addEventListener(
  "load",
  (event) => {
    document.body.classList.remove("preload");
    headerFormat();
  },
  false,
);

// Sticky header
const headerFormat = () => {
  const target = document.querySelector(".mainImage");
  const header = document.querySelector("header");
  const container = document.querySelector("header .container");
  const logo = document.querySelector("#logo svg");

  if (target) {
    if (window.scrollY > target.offsetTop + target.offsetHeight) {
      header.classList.remove("absolute", "text-white");
      header.classList.add("fixed", "bg-white", "text-darkBlue");
      container.classList.add("md:py-3");
      logo.classList.add("fill-darkBlue");
    } else {
      header.classList.add("absolute", "text-white");
      header.classList.remove("fixed", "bg-white", "text-darkBlue");
      container.classList.remove("md:py-3");
      logo.classList.remove("fill-darkBlue");
    }
  }
};

window.addEventListener("scroll", headerFormat, false);

// UI buttons
$(".btt").click(function () {
  $("html, body").animate({ scrollTop: 0 }, "slow");
});

$(".scrollContent").click(function () {
  $("html, body").animate(
    {
      scrollTop: $("#content").offset().top,
    },
    "slow",
  );
});

$(".accordion-toggle").click(function () {
  $(this).find("svg").toggleClass("rotate-180 !fill-cyan");
  $(this).next().slideToggle();
});
