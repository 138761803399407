import Swiper from "swiper/swiper-bundle.mjs";

const testimonials = new Swiper(".testimonials", {
  centeredSlides: true,
  loop: true,
  slidesPerView: 1,
  navigation: {
    nextEl: ".button-next",
    prevEl: ".button-prev",
  },
  pagination: {
    el: ".swiper-pagination",
    bulletClass: "swiper-pagination-bullet border",
  },
});

const works = new Swiper(".works", {
  slidesPerView: "auto",
  spaceBetween: 32,
  slidesOffsetBefore: 40,
  slidesOffsetAfter: 40,
  mousewheel: {
    forceToAxis: true,
  },
  scrollbar: {
    el: ".swiper-scrollbar",
  },
  breakpoints: {
    768: {
      slidesPerView: "auto",
      slidesOffsetBefore: 80,
      slidesOffsetAfter: 80,
    },
    1441: {
      slidesPerView: "auto",
      slidesOffsetBefore: 160,
      slidesOffsetAfter: 160,
    },
  },
  on: {
    init: function () {
      $(".works").removeClass("opacity-0");
    },
  },
});

const horizontalScroll = new Swiper(".horizontalScroll", {
  slidesPerView: 1.25,
  spaceBetween: 32,
  slidesOffsetBefore: 40,
  slidesOffsetAfter: 40,
  mousewheel: {
    forceToAxis: true,
  },
  scrollbar: {
    el: ".swiper-scrollbar",
  },
  breakpoints: {
    768: {
      slidesPerView: "auto",
      slidesOffsetBefore: 80,
      slidesOffsetAfter: 80,
    },
    1441: {
      slidesPerView: "auto",
      slidesOffsetBefore: 160,
      slidesOffsetAfter: 160,
    },
  },
  on: {
    init: function () {
      console.log("init");
      $(".horizontalScroll").removeClass("opacity-0");
    },
  },
});

const thumbSlider = new Swiper(".servicesThumbs", {
  slidesPerView: "auto",
  direction: "vertical",
  watchSlidesProgress: true,
});

const services = new Swiper(".services", {
  spaceBetween: 30,
  slidesPerView: "auto",
  direction: "vertical",
  thumbs: {
    swiper: thumbSlider,
  },
  breakpoints: {
    1024: {
      slidesPerView: 1,
      direction: "horizontal",
    },
  },
});

export { testimonials, works, services, horizontalScroll };
