const menu = document.querySelector("#main-navigation");
const hamburger = document.querySelector("#hamburger");
const menuIcon = document.querySelector(".menuIcon");
const closeIcon = document.querySelector(".closeIcon");
const logo = document.querySelector("#logo svg");

// Toggle nav
hamburger.addEventListener("click", function () {
  menu.classList.contains("hidden") ? openNav() : closeNav();
});

// Hide nav on window resise
window.addEventListener("resize", (event) => {
  if (window.innerWidth > 900) {
    closeNav();
  }
});

$(".subnavToggle").click(function () {
  if ($(window).width() < 1024) {
    $(this).next().slideToggle();
  }
});

// Functions
const openNav = () => {
  menu.classList.remove("hidden");
  menu.classList.add("text-white");
  logo.classList.add("!fill-cyan");
  menuIcon.classList.add("hidden");
  closeIcon.classList.remove("hidden");
};

const closeNav = () => {
  menu.classList.add("hidden");
  menu.classList.remove("text-white");
  logo.classList.remove("!fill-cyan");
  menuIcon.classList.remove("hidden");
  closeIcon.classList.add("hidden");
};

export { openNav, closeNav };
